import { ChangeEvent, useEffect, useState } from "react"
import { AppContainer, ApplyNowButton, CalculateYieldButton, CalculatorContainer, ComparisonHeader, ComparisonRow, ComparisonTier, ComparisonYield, DepositAmount, DepositContainer, InfoContainer, InfoDescription, InfoDescriptionContainer, Input, YieldBreakdownCard, YieldBreakdownLabel, YieldBreakdownMonthly, YieldBreakdownRate, YieldBreakdownYearly, YieldCalculatorHeader } from "./styles"
import { calculateAnnualYield } from "../../calculate"
import { StickyHeader } from "../StickyHeader"
import { formatCurrency } from "../../format-currency"

interface ActiveTier {
  yield: string;
  label: string;
  tier: string;
}

export const App = () => {
  const getActiveTier = (depositAmount: number): ActiveTier => {
    const tier1 = 250000;
    const tier2 = 10000000;

    if (depositAmount < tier1) {
      return {
        yield: "4.00% APY",
        label: "Based on deposits",
        tier: "$250k or less"
      }
    } else if (depositAmount < tier2) {
      return {
        yield: "4.00% APY",
        label: "Based on deposits between",
        tier: "$250k - $10M"
      }
    } else {
      return {
        yield: "4.00% APY",
        label: "Based on deposits",
        tier: "$10M+"
      }
    }
  }

  const parseFormattedNumber = (formattedValue: string): number => {
    // Remove commas and any other non-numeric characters except the decimal point
    const cleanedValue = formattedValue.replace(/[^\d.-]/g, '');

    // Parse the cleaned string to a float
    const parsedValue = parseFloat(cleanedValue);

    // Check if the result is a valid number
    if (isNaN(parsedValue)) {
      return NaN;
    }

    return parsedValue;
  };

  const [depositAmount, setDepositAmount] = useState<string | null>(null)
  const [calculatedAmount, setCalculatedAmount] = useState<number>(0)
  const [activeTier, setActiveTier] = useState<ActiveTier>(getActiveTier(parseFormattedNumber(depositAmount || "0")))
  const [calculatedTier, setCalculatedTier] = useState<ActiveTier>(getActiveTier(calculatedAmount))

  const handleCalculateYield = () => {
    let amount = parseFormattedNumber(depositAmount || "0")
    setCalculatedAmount(amount)
  }

  useEffect(() => {
    setActiveTier(getActiveTier(parseFormattedNumber(depositAmount || "0")))
  }, [depositAmount])

  useEffect(() => {
    setCalculatedTier(getActiveTier(calculatedAmount))
  }, [calculatedAmount])

  const formatNumberInput = (value: string) => {
    // Remove any non-digit characters except for the decimal point
    let cleanedValue = value.replace(/[^\d.]/g, '');

    // Ensure only one decimal point
    const parts = cleanedValue.split('.');
    if (parts.length > 2) {
      cleanedValue = parts[0] + '.' + parts.slice(1).join('');
    }

    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = cleanedValue.split('.');

    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the formatted integer part with the decimal part (if it exists)
    let formattedValue = formattedIntegerPart;
    if (decimalPart !== undefined) {
      formattedValue += '.' + decimalPart;
    }

    return formattedValue;
  };

  const handleNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = formatNumberInput(inputValue);

    setDepositAmount(formattedValue);
  };

  const annualYieldEarned = calculateAnnualYield(calculatedAmount)
  const monthlyYieldEarned = annualYieldEarned / 12

  return (
    <AppContainer>
      <StickyHeader />
      <YieldCalculatorHeader>Holding's Yield Calculator</YieldCalculatorHeader>
      <CalculatorContainer>
        <InfoContainer>
          <DepositContainer>
            <DepositAmount>
              Deposit Amount:
            </DepositAmount>
            <Input
              id="deposit-amount"
              type="text"
              placeholder="$0.00"
              value={depositAmount ?? ""}
              onChange={handleNumberInputChange}
            />
            <CalculateYieldButton
              disabled={!depositAmount}
              onClick={handleCalculateYield}
            >
              Update
            </CalculateYieldButton>
          </DepositContainer>
          <InfoDescriptionContainer>
            <ComparisonHeader>
              What you will earn:
            </ComparisonHeader>
            <ComparisonRow
              activeTier={activeTier.tier === "$250k or less"}
            >
              <ComparisonYield>
                4.00% APY
              </ComparisonYield>
              <ComparisonTier>
                On all deposits
              </ComparisonTier>
            </ComparisonRow>
            <InfoDescription>
              $5M in FDIC Insurance
            </InfoDescription>
            <InfoDescription>
              No fees, fully liquid, can be moved same-day any time.
            </InfoDescription>
          </InfoDescriptionContainer>
        </InfoContainer>
        <YieldBreakdownCard>
          <YieldBreakdownMonthly>
            {formatCurrency(monthlyYieldEarned.toString()).singleLine}
          </YieldBreakdownMonthly>
          <YieldBreakdownLabel>
            Monthly Earnings
          </YieldBreakdownLabel>
          <YieldBreakdownYearly>
            {formatCurrency(annualYieldEarned.toString()).singleLine}
          </YieldBreakdownYearly>
          <YieldBreakdownLabel>
            Annual Earnings
          </YieldBreakdownLabel>
          <YieldBreakdownRate>
            {calculatedTier.yield}
          </YieldBreakdownRate>
          <ApplyNowButton
            href="https://dashboard.holdings.io/sign-up?referrer=%22https://holdings.io%22&affiliate=%22yield_calculator%22"
            rel="noreferrer noopener"
          >
            Apply Now
          </ApplyNowButton>
        </YieldBreakdownCard>
      </CalculatorContainer>
    </AppContainer >
  )
}